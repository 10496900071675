import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from '../components/layout'
import { Link } from "gatsby"
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import { OutboundLink } from "gatsby-plugin-google-gtag"

// FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGraduationCap, faCertificate, faAward } from '@fortawesome/free-solid-svg-icons'
library.add(faGraduationCap, faCertificate, faAward)


// markup
const IndexPage = () => {



  const { headerImage } = useStaticQuery(
    graphql`
query {
  headerImage: file(relativePath: { eq: "cover-about-portraitdark-2800x1800-63059877294.jpg" }) {
    childImageSharp {
      gatsbyImageData(
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
}
`
  );








  return (

    <Layout pageTitle="About Me">




      <section className="border-bottom">
        <div className="container">
          <div className="row align-items-stretch">
            <div className="col-12 col-md-6 offset-md-1 order-md-2">


              <div className="d-md-none img-cover">
                <div className="w-100">
                  <GatsbyImage image={headerImage.childImageSharp.gatsbyImageData} alt="Portrait of David Baxter" imgClassName="img-fluid" />
                </div>
              </div>

              <div className="position-relative h-100 vw-50 d-none d-md-block" data-aos="fade-left">

                <div className="flickity-button-bottom flickity-button-white h-100 w-100">
                  <BgImage Tag="div" className="w-100 h-100 bg-cover" image={headerImage.childImageSharp.gatsbyImageData} alt="Portrait of David Baxter">
                  </BgImage>
                </div>







                <div className="shape shape-start shape-fluid-y svg-shim text-white">
                  <svg viewBox="0 0 100 1544" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h100v386l-50 772v386H0V0z" fill="currentColor" /></svg>              </div>
              </div>

            </div>



            <div className="col-12 col-md-5 py-6 py-md-10 order-md-1" data-aos="fade-right">

              <h1 className="display-3">
                Hi there! <br />I'm <span className="fw-bolder text-primary">David Baxter</span>.
              </h1>

              <p className="lead text-muted mb-0 mb-md-0">
                An experienced video marketer and live stream engineer.
              </p>

            </div>
          </div>
        </div>
      </section>















      <section className="pt-8 pt-md-11 bg-gradient-light-white pb-10 pb-md-12" data-aos="fade-up">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center">

              <h2>
                Techie and Storyteller
              </h2>

              <p className="fs-lg text-gray-700 mb-7 mb-md-9">
                My expertise lies at the intersection of <strong>marketing</strong>, <strong>video production</strong>, and <strong>live events</strong>. I combine my love for the latest in nerdy tech production gear with a relentless drive to tell compelling stories that matter.
              </p>

              <Link to="/services/" className="fw-bold text-decoration-none">
                Learn more about what I can do <i className="fe fe-arrow-right ms-3"></i>
              </Link>

            </div>
          </div>
        </div>
      </section>


















      <hr class="hr-md mb-10 py-0 mx-auto d-md-none" />


















      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x text-white">
          <svg viewBox="0 0 2880 480" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z" fill="currentColor" /></svg>      </div>
      </div>

      <section className="pt-0 pt-lg-0 pb-12 pb-md-14 pb-lg-15 bg-white">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-md-6 col-lg-5 order-md-2" data-aos="fade-up">


              <span className="badge rounded-pill bg-dark-soft mb-3">
                <span className="h6 text-uppercase">Career</span>
              </span>

              <h2 className="mb-6">
                I currently lead <OutboundLink href="https://www.biola.edu" target="_blank" rel="noopener noreferrer">Biola University's</OutboundLink> Video + Photo Marketing team.
              </h2>

              <p className="fs-lg text-gray-700 mb-6">
                Building upon each successful project, I’ve grown the team from just one person (me!) to a thriving team of about 15.
              </p>

              <p className="fs-lg text-gray-700 mb-6">
                I love the variety of projects my team works on — from ads, to events, to photos, to podcasts.
              </p>

              <p className="fs-lg text-gray-700 mb-6">
                Each day brings a new, exciting, creative challenge — but my focus is always on telling Biola’s story to the next generation of students.
              </p>

              <p className="fs-lg text-gray-700 mb-6">
                I also do part-time contract work for <OutboundLink href="https://www.oasisla.org" target="_blank" rel="noopener noreferrer">Oasis Church</OutboundLink> — primarily around video and network engineering.
              </p>


            </div>


            <div className="col-12 col-md-6 order-md-1">

              <div className="row">
                <div className="col-6 mt-8 me-n5" data-aos="fade-up">

                  <StaticImage src="../../images/about-sidebar-tall-david-hivelight-1320x2080-50733319114.jpg" alt="David standing next to a large Hive film light" className="img-fluid mb-4 rounded" />

                  <StaticImage src="../../images/about-sidebar-med-dtla-team-1320x1050-51711642326.jpg" alt="David with his team at a Downtown LA shoot" className="img-fluid rounded" />

                </div>
                <div className="col-6" data-aos="fade-up" data-aos-delay="100">

                  <StaticImage src="../../images/about-sidebar-med-horton-shoot-1320x1050-50718347253.jpg" alt="Film students at Biola University" className="img-fluid mb-4 rounded" />

                  <StaticImage src="../../images/about-sidebar-tall-commencement-hands-1320x2080-67380599571.jpg" alt="David with some of his team members" className="img-fluid rounded" />

                </div>
              </div>

            </div>
          </div>
        </div>
      </section>










      <div className="position-relative mt-n8 mt-md-n12">
        <div className="shape shape-bottom shape-fluid-x text-dark">
          <svg viewBox="0 0 2880 480" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M720 0c720 240 1440 240 1440 240h720v240H0V0h720z" fill="currentColor" /></svg>      </div>
      </div>












      <section className="bg-gradient-dark-black pt-4 pt-md-0 pb-8 pb-md-8 pb-lg-8">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-md-6 col-lg-5" data-aos="fade-up">


              <span className="badge rounded-pill bg-light-soft mb-3">
                <span className="h6 text-uppercase">Free Time</span>
              </span>

              <h2 className="text-gray-100">
                I love a good adventure.
              </h2>

              <p className="fs-lg text-gray-300 mb-6">
                In my free time, you'll likely find me hiking, biking, camping, or otherwise exploring some of the magnificent natural sites our world has to offer.
              </p>

              <p className="fs-lg text-gray-300 mb-6">
                My bucket list will be complete only after visiting all the national parks, seeing the wonders of the world, and competing on The Amazing Race.
              </p>

              <p className="fs-lg text-gray-300 mb-6">
                Space fascinates me, and one of the most awe-inspiring sites I've ever seen is a sunset rocket launch over Los Angeles.
              </p>




            </div>


            <div className="col-12 col-md-6">

              <div className="row">
                <div className="col-6 mt-8 me-n5" data-aos="fade-up">

                  <StaticImage src="../../images/about-sidebar-tall-glacierpoint-1320x2080.jpg" alt="View of Half Dome from Glacier Point - my favorite turn in California" className="img-fluid mb-4 rounded" />

                  <StaticImage src="../../images/about-sidebar-med-franzjosef-1320x1050.jpg" alt="David atop Franz Josef Glacier in New Zealand" className="img-fluid rounded" />

                </div>
                <div className="col-6" data-aos="fade-up" data-aos-delay="100">

                  <StaticImage src="../../images/about-sidebar-med-skelligmichael-1320x1050.jpg" alt="David on Skellig Michael in Ireland" className="img-fluid mb-4 rounded" />

                  <StaticImage src="../../images/about-sidebar-tall-joshuatree-1320x2080.jpg" alt="Joshua Tree" className="img-fluid rounded" />

                </div>
              </div>

            </div>
          </div>
        </div>
      </section>








      <div className="position-relative mt-n0 mt-md-n0">
        <div className="shape shape-bottom shape-fluid-x shape-flip-x text-white">
          <svg viewBox="0 0 2880 480" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M720 0c720 240 1440 240 1440 240h720v240H0V0h720z" fill="currentColor" /></svg>      </div>
      </div>
















      <section className="py-8 pt-md-10 pb-md-4 bg-gradient">
        <div className="container">




          <div className="row">




            <div className="col-12 col-md-4">
              <div className="card card-border border-primary shadow-light-lg mb-6 mb-md-8" data-aos="fade-up">
                <div className="card-body">

                  <h6 className="text-uppercase text-primary fs-4 fw-bold d-inline-block mb-5 me-1">
                    <FontAwesomeIcon icon="graduation-cap" /> Education
                  </h6>

                  <div>
                    <div className="list-group list-group-flush">

                      <span className="list-group-item text-reset text-decoration-none">
                        <h5 className="fw-bold mb-1">
                          Bachelor of Arts (B.A.)<br />
                          Film &amp; Television Production
                        </h5>
                        <p className="fs-sm mb-0">
                          Biola University
                        </p>
                        <p className="fs-6 text-muted mb-0">
                          2004 — 2007 · Cum Laude
                        </p>
                      </span>


                      <span className="list-group-item text-reset text-decoration-none">
                        <h5 className="fw-bold mb-1">
                          Emerging Leaders
                        </h5>
                        <p className="fs-sm mb-0">
                          Biola University
                        </p>
                        <p className="fs-6 text-muted mb-0">
                          2016 — 2017
                        </p>
                      </span>


                    </div>
                  </div>
                </div>
              </div>
            </div>









            <div className="col-12 col-md-4">
              <div className="card card-border border-info shadow-light-lg mb-6 mb-md-8" data-aos="fade-up" data-aos-delay="100">
                <div className="card-body">

                  <h6 className="text-uppercase text-info fs-4 fw-bold d-inline-block mb-5 me-1">
                    <FontAwesomeIcon icon="certificate" /> Certifications
                  </h6>

                  <div>
                    <div className="list-group list-group-flush">

                      <span className="list-group-item text-reset text-decoration-none">
                        <h5 className="fw-bold mb-1">
                          FAA Remote Pilot, Part 107
                        </h5>
                        <p className="fs-6 text-muted mb-0">
                          2016 — Current
                        </p>
                      </span>


                      <span className="list-group-item text-reset text-decoration-none">
                        <h5 className="fw-bold mb-1">
                          Blackmagic DaVinci Resolve 17
                        </h5>
                        <p className="fs-sm mb-0">
                          Certified User: Editing
                        </p>
                        <p className="fs-6 text-muted mb-0">
                          2021
                        </p>
                      </span>


                      <span className="list-group-item text-reset text-decoration-none">
                        <h5 className="fw-bold mb-1">
                          Audinate Dante Certification
                        </h5>
                        <p className="fs-sm mb-0">
                          Levels 1, 2, and 3
                        </p>
                        <p className="fs-6 text-muted mb-0">
                          2019
                        </p>
                      </span>


                    </div>
                  </div>
                </div>
              </div>
            </div>






            <div className="col-12 col-md-4">
              <div className="card card-border border-success shadow-light-lg mb-6 mb-md-8" data-aos="fade-up" data-aos-delay="200">
                <div className="card-body">

                  <h6 className="text-uppercase text-success fs-4 fw-bold d-inline-block mb-5 me-1">
                    <FontAwesomeIcon icon="award" /> Awards
                  </h6>

                  <div>
                    <div className="list-group list-group-flush">

                      <span className="list-group-item text-reset text-decoration-none">
                        <h5 className="fw-bold mb-1">
                          President's Award for Excellence
                        </h5>
                        <p className="fs-sm mb-0">
                          Biola University
                        </p>
                        <p className="fs-6 text-muted mb-0">
                          2016
                        </p>
                      </span>


                      <span className="list-group-item text-reset text-decoration-none">
                        <h5 className="fw-bold mb-1">
                          Values Award
                        </h5>
                        <p className="fs-sm mb-0">
                          Biola University
                        </p>
                        <p className="fs-6 text-muted mb-0">
                          2009, 2013
                        </p>
                      </span>


                    </div>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>
      </section>







      <hr class="hr-md my-2 py-0 mx-auto d-md-none" />




      <section className="pt-8 pt-md-11 pb-8 pb-md-12">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 col-lg-7">

              <div className="mb-8 mb-md-0" data-aos="fade-right">

                <StaticImage src="../../images/about-losangeles-from-griffithpark-1400x900.jpg" alt="View of the Hollywood sign from Griffith Park" className="screenshot img-fluid mw-md-110 float-end me-md-6 mb-6 mb-md-0" />

              </div>

            </div>
            <div className="col-12 col-md-6 col-lg-5" data-aos="fade-left">



              <h2>
                Based in Los Angeles.
              </h2>

              <p className="fs-lg text-gray-700 mb-6">
                Born and raised in the beautiful Pacific Northwest, I currently live and work in sunny Los Angeles.
              </p>

              <p className="fs-lg text-gray-700 mb-6">
                I've also done jobs in New York, Chicago, Seattle, Vancouver, Portland, San Francisco, San Diego, Dallas, Washington, D.C., just to name a few.
              </p>





            </div>
          </div>
        </div>
      </section>








































      <section className="pb-8 pb-md-11 border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12">

              <div className="row gx-4">
                <div className="col-4" data-aos="fade-right" data-aos-delay="50">

                  <StaticImage src="../../images/about-collage-skelligmichael-steps-1920x2328.jpg" alt="Steps leading up to Skellig Michael in Ireland" imgClassName="img-fluid rounded shadow-lg" />


                </div>
                <div className="col-3" data-aos="fade-up" data-aos-delay="200">

                  <StaticImage src="../../images/about-collage-spacex-launch-1410x1260.jpg" alt="SpaceX launch over Los Angeles" className="img-fluid rounded shadow-lg mb-4" imgClassName="img-fluid rounded shadow-lg mb-4" />

                  <StaticImage src="../../images/about-collage-rooftop-1410x954-51711422862.jpg" alt="David waving hello" imgClassName="img-fluid rounded shadow-lg" />

                </div>
                <div className="col-5" data-aos="fade-left" data-aos-delay="400">

                  <div className="row gx-5 mb-4">
                    <div className="col-5">

                      <StaticImage src="../../images/about-collage-milford-942x852.jpg" alt="David in Milford Sound, New Zealand" imgClassName="img-fluid rounded shadow-lg" />

                    </div>
                    <div className="col-7">

                      <StaticImage src="../../images/about-collage-griffith-jan2018-1362x852.jpg" alt="Hiking in Griffith Park at sunset" imgClassName="img-fluid rounded shadow-lg" />

                    </div>
                  </div>

                  <StaticImage src="../../images/about-collage-mt-whitney-2430x1380.jpg" alt="David standing atop Mt. Whitney — the highest point in the Continental United States" imgClassName="img-fluid rounded shadow-lg" />

                </div>
              </div>

            </div>
          </div>
        </div>
      </section>




























      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x text-dark">
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor" /></svg>      </div>
      </div>

      <section className="py-8 pt-md-11 pb-md-6 bg-gradient-dark-black">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center">

              <h1 className="fw-bold text-white">
                Want to explore more?
              </h1>

              <p className="fs-lg text-gray-100 mb-7 mb-md-9">
                Check out some of <Link to="/work/">my past work</Link>, see the kinds of <Link to="/services/">services</Link> I can provide, or <Link to="/contact/">get in touch</Link>.
              </p>

            </div>
          </div>


        </div>
      </section>





























    </Layout>


  )
}

export default IndexPage
